import React, { useState } from "react";
import { CSW, CSTitle, A } from "./Base";
// Data //
const contacts = [
    {
        type: "email",
        label: "Email",
        displayText: "hello@octobase.org",
        href: "mailto:hello@octobase.org",
    },
    // {
    //   type: "phone",
    //   label: "Phone/Text",
    //   displayText: "+1 (800) XXX-XXXX",
    //   href: "tel:+1800XXXXXXX",
    // },
    {
        type: "webLink",
        label: "LinkedIn",
        displayText: "https://linkedin.com/company/octobasehq",
        href: "https://linkedin.com/company/octobasehq",
    },
    {
        type: "webLink",
        label: "GitHub",
        displayText: "https://github.com/octobasehq",
        href: "https://github.com/octobasehq",
    },
    {
        type: "webLink",
        label: "Website",
        displayText: "https://www.octobase.org",
        href: "https://www.octobase.org",
    },
    {
        type: "address",
        label: "Address",
        displayText: "5205 N O'Connor Blvd, Irving, TX 75039, USA",
        href: "https://maps.app.goo.gl/iGG4eGNqMCxwWkcn7",
    },
];
// Views //
const ContactSection = () => {
    return (React.createElement(CSW, { styles: { gap: "20px" } },
        React.createElement(CSTitle, null, "Contact:"),
        React.createElement("div", { style: {
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                fontSize: "12px",
                fontWeight: "bold",
                fontFamily: "monospace",
                lineHeight: "1.58",
                color: "#333333",
                padding: "0px 4px",
            } }, contacts.map((contact) => (React.createElement(ContactItem, { key: contact.label, contact: contact }))))));
};
const ContactItem = ({ contact }) => {
    const [isHovered, setIsHovered] = useState(false);
    return (React.createElement("div", { onMouseEnter: () => setIsHovered(true), onMouseLeave: () => setIsHovered(false) },
        React.createElement("span", null,
            contact.label,
            ": "),
        React.createElement(A, { href: contact.href, text: contact.displayText, styles: {
                wordSpacing: "-0.05em",
                textDecorationThickness: isHovered ? "2px" : "1px",
                color: "#606060",
                transition: "text-decoration-thickness 0.2s ease-in-out",
            } })));
};
export { ContactSection };
