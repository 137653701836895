import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import Homepage from "./Homepage";
import "./index.css";
const container = document.getElementById("app-container");
if (container) {
    const root = createRoot(container);
    root.render(React.createElement(StrictMode, null,
        React.createElement(Homepage, null)));
}
else {
    console.error("Homepage: #app-container not found! Unable to render Homepage components.");
}
