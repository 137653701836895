import React, { useEffect, useRef } from "react";
import { HomepageProvider, useHomepageCtx } from "./HomepageCtx";
import Header from "./Header";
import Footer from "./Footer";
import { AboutSection } from "./About";
import { ProductsSection } from "./ProductV2";
import { ContactSection } from "./Contact";
import { CareersSection } from "./Careers";
import { FundingSection } from "./Funding";
import "./Homepage.css";
// Views //
const HomepageWithContext = () => {
    return (React.createElement(HomepageProvider, null,
        React.createElement(Homepage, null)));
};
const Homepage = () => {
    const { themeAlt, setThemeAlt } = useHomepageCtx();
    // Randomly set the themeAlt to true or false
    useEffect(() => {
        setThemeAlt(Math.random() < 0.5);
    }, []);
    return (React.createElement("div", { id: "homepage-container", style: {
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh", // take full viewport height
            backgroundColor: "#f9f9f9", // #e8e8e8, #9e9e9e, #f9ffed, #f9f9f9
            // backgroundImage: "radial-gradient(#9e9e9e 1px, transparent 1px)",
            // backgroundSize: "20px 20px",
            // backgroundPosition: "0 0, 10px 10px",
            // backgroundRepeat: "repeat",
        }, onClick: (e) => {
            e.preventDefault();
            e.stopPropagation();
            setThemeAlt(!themeAlt);
        } },
        React.createElement("div", { style: {
                display: "flex",
                flexDirection: "column",
                width: "100%", // Full width of the viewport
                maxWidth: "1024px", // Limit the width to 1024px
                margin: "0 auto", // Center the container horizontally
                minHeight: "100vh", // Ensure the container takes full viewport height
                backgroundColor: "white",
                // border: "1px solid #dddddd",
                // borderRadius: "2px",
                boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
                // overflow: "hidden",
            } },
            React.createElement(Header, null),
            React.createElement(ContentBody, null),
            React.createElement(Footer, null))));
};
const ContentBody = () => {
    const { setSelectedTab, themeAlt } = useHomepageCtx();
    const sectionRefs = useRef({
        home: useRef(null),
        about: useRef(null),
        products: useRef(null),
        contact: useRef(null),
        careers: useRef(null),
    });
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + window.innerHeight / 4;
            let currentSection = "about";
            Object.entries(sectionRefs.current).forEach(([key, ref]) => {
                if (ref.current && ref.current.offsetTop <= scrollPosition) {
                    currentSection = key;
                }
            });
            setSelectedTab(currentSection);
        };
        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Call once to set initial state
        return () => window.removeEventListener("scroll", handleScroll);
    }, [setSelectedTab]);
    return (React.createElement("div", { style: {
            flex: "1", // Take up the remaining space
            padding: "24px 40px",
            backgroundColor: themeAlt ? "#f1f8e9" : "#ffffff", // #ffffff, #f1f8e9
            display: "flex",
            flexDirection: "column",
            gap: "20px",
        } },
        React.createElement("div", { id: "about", ref: sectionRefs.current.about },
            React.createElement(AboutSection, null)),
        React.createElement("div", { id: "products", ref: sectionRefs.current.products },
            React.createElement(ProductsSection, null)),
        React.createElement("div", { id: "contact", ref: sectionRefs.current.contact },
            React.createElement(ContactSection, null)),
        React.createElement("div", { id: "careers", ref: sectionRefs.current.careers },
            React.createElement(CareersSection, null)),
        React.createElement("div", { id: "funding" },
            React.createElement(FundingSection, null))));
};
export default HomepageWithContext;
