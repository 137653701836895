var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { useHomepageCtx } from "./HomepageCtx";
// Content Section Wrapper (CSW) (for sections within the content body)
const CSW = ({ children, styles, }) => {
    const { themeAlt } = useHomepageCtx();
    return (React.createElement("div", { style: Object.assign({ display: "flex", flexDirection: "column", gap: "4px", fontSize: "14px", fontWeight: "400", fontFamily: "Helvetica", lineHeight: "1.58", color: "#333333", textAlign: "justify", wordSpacing: "-0.2px", padding: "42px", backgroundColor: themeAlt ? "#ffffff" : "#f1f8e9", borderRadius: "24px" }, styles), onClick: (e) => e.stopPropagation() }, children));
};
// Content Section Title (for titles within the CSW)
const CSTitle = ({ children, styles, }) => {
    return (React.createElement("span", { style: Object.assign({ fontSize: "14px", fontWeight: "600", color: "#424242", backgroundColor: "#dcedc8", border: "1px solid transparent", borderRadius: "6px", padding: "0px 6px", width: "fit-content" }, styles) }, children));
};
// Highlighted Text (Ht)
const Ht = (text, type = "ul", styles) => {
    const underlineStyles = Object.assign({ textDecoration: "underline", textDecorationColor: "#a5d6a7", textUnderlineOffset: "3px", textDecorationThickness: "2px" }, styles);
    const backgroundStyles = Object.assign({ backgroundColor: "#dcedc8", border: "1px solid transparent", borderRadius: "4px", padding: "0px 2px", width: "fit-content" }, styles);
    const stType = type === "ul" ? underlineStyles : backgroundStyles;
    return React.createElement("span", { style: stType }, text);
};
// Anchor (A) tag with custom brand styles
const A = (_a) => {
    var { href, text, styles } = _a, props = __rest(_a, ["href", "text", "styles"]);
    return (React.createElement("a", Object.assign({}, props, { style: Object.assign({ color: "#333333", textDecoration: "underline", textDecorationColor: "#a5d6a7", textUnderlineOffset: "3px", textDecorationThickness: "2px", cursor: "pointer" }, styles), target: "_blank", rel: "noopener noreferrer", href: href }), text));
};
export { CSW, CSTitle, Ht, A };
