import React, { useState } from "react";
// Context //
const HomepageCtx = React.createContext({
    selectedTab: "about",
    setSelectedTab: () => { },
    headerHeight: 0,
    setHeaderHeight: () => { },
    themeAlt: false,
    setThemeAlt: () => { },
});
// Context Hook //
const useHomepageCtx = () => React.useContext(HomepageCtx);
// Context Provider //
const HomepageProvider = ({ children }) => {
    const [selectedTab, setSelectedTab] = useState("about");
    const [headerHeight, setHeaderHeight] = useState(0);
    const [themeAlt, setThemeAlt] = useState(false);
    return (React.createElement(HomepageCtx.Provider, { value: {
            selectedTab,
            setSelectedTab,
            headerHeight,
            setHeaderHeight,
            themeAlt,
            setThemeAlt,
        } }, children));
};
export { useHomepageCtx, HomepageProvider };
