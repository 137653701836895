var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from "react";
// Views //
const Footer = () => {
    return (
    // Footer container
    React.createElement("div", { style: {
            width: "100%",
            position: "sticky",
            bottom: "0",
            zIndex: "1000",
            backgroundColor: "#a4ff00", // #dddddd, #a4ff00
            marginTop: "2px",
        } },
        React.createElement("div", { style: {
                minHeight: "16px",
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                alignItems: "center",
                fontSize: "12px",
                fontWeight: "bold",
                fontFamily: "monospace",
                color: "#424242",
                padding: "6px 24px 4px 24px",
                // Note:
                // flexGrow: "1" will make the content div take up the remaining space.
                // It will push the last div in a flex container (i.e. socials) to the
                // right when used in conjunction with justifyContent: "space-between".
                flexGrow: "1",
                justifyContent: "space-between",
            } },
            React.createElement(CopyrightInfo, null),
            React.createElement(Socials, null))));
};
const CopyrightInfo = () => {
    return React.createElement("span", null,
        "Copyright \u00A9 ",
        new Date().getFullYear());
};
const Socials = () => {
    // Wrapper for anchor tags specific to the footer
    const A = (_a) => {
        var { href, children } = _a, props = __rest(_a, ["href", "children"]);
        const [isHovered, setIsHovered] = useState(false);
        return (React.createElement("a", Object.assign({ target: "_blank", rel: "noopener noreferrer", href: href, style: {
                textDecoration: isHovered ? "underline" : "none",
                color: isHovered ? "#333333" : "#424242",
                fontWeight: "bold",
                fontFamily: "monospace",
                fontSize: "12px",
                alignItems: "center",
                cursor: "pointer",
            }, onMouseEnter: () => setIsHovered(true), onMouseLeave: () => setIsHovered(false) }, props), children));
    };
    return (React.createElement("span", { style: {
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            textDecoration: "none",
            color: "#424242",
            fontWeight: "bold",
            fontFamily: "monospace",
            fontSize: "12px",
            alignItems: "center",
        } },
        React.createElement(A, { href: "mailto:hello@octobase.org" }, "Email"),
        React.createElement("span", null, "|"),
        React.createElement(A, { href: "https://www.linkedin.com/company/octobasehq/" }, "LinkedIn"),
        React.createElement("span", null, "|"),
        React.createElement(A, { href: "https://github.com/octobasehq" }, "GitHub")));
};
export default Footer;
