import React from "react";
import { CSW, Ht, A } from "./Base";
// Views //
const FundingSection = () => {
    return (React.createElement(CSW, { styles: { gap: "14px", border: "2px solid #a5d6a7" } },
        React.createElement("div", { style: {
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                padding: "0px 12px 0px 6px",
                wordBreak: "break-word",
                wordWrap: "break-word",
            } },
            React.createElement("p", null,
                "We are on a mission to build smart tools and interfaces that enhance learning and knowledge exploration. Currently, we are seeking",
                " ",
                Ht("seed / series A funding", "bg-ul"),
                " to advance our development efforts and accelerate our progress. If you're interested in partnering with us, please",
                " ",
                React.createElement(A, { href: "mailto:hello@octobase.org", text: "email us" }),
                " to learn more about our work and to discuss potential opportunities. Thank you!"),
            React.createElement("p", { style: {
                    padding: "0px 12px 0px 6px",
                    fontStyle: "italic",
                } }, "\"The best way to predict the future is to invent it.\" \u2014 Alan Kay"))));
};
export { FundingSection };
