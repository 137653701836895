import React from "react";
import { CSW, CSTitle, Ht } from "./Base";
// Views //
const AboutSection = () => {
    // Highlighted text wrap with custom styles
    const Htw = (text) => {
        return Ht(text, "bg-ul", {
            textUnderlineOffset: "2px",
            textDecorationThickness: "2px",
            fontWeight: "500",
            // fontStyle: "italic",
            // textDecorationStyle: "dotted",
        });
    };
    // Anchor tag with custom styles
    const A = (href, text) => {
        return (React.createElement("a", { href: href, target: "_blank", rel: "noreferrer", style: {
                textDecoration: "underline",
                fontWeight: "500",
                color: "#424242",
            } }, text));
    };
    return (React.createElement(CSW, { styles: { gap: "20px" } },
        React.createElement(CSTitle, null, "Welcome!"),
        React.createElement("div", { style: {
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                padding: "0px 12px 0px 6px",
                overflowWrap: "break-word",
                wordSpacing: "-0.5px",
            } },
            React.createElement("p", null,
                "At ",
                Htw("Octobase"),
                ", we're developing tools to help learners and thinkers effectively navigate and curate information in their knowledge journey!"),
            React.createElement("p", null,
                "We are a small team of engineers, designers, problem-solvers, and lifelong learners passionate about creating",
                " ",
                Htw("smart tools and interfaces for learning and knowledge exploration"),
                ". We believe the future of learning and discovery lies in tools that actively support users in their quest for self-learning and deep thinking."),
            React.createElement("p", null,
                "In today's digital age, while access to information is abundant, the tools and experiences needed to seamlessly navigate, recall, link, and curate relevant content often fall short. As the web serves as the primary source for research and information needs, our mission begins with enhancing the navigation, discovery, and curation of knowledge online. Our goal is to equip users with tools that make interacting with digital content more seamless, contextual, and insightful \u2014 empowering their learning journey through advancements in",
                " ",
                A("https://en.wikipedia.org/wiki/Artificial_intelligence", "AI"),
                " and",
                " ",
                A("https://en.wikipedia.org/wiki/Information_retrieval", "Search"),
                " ",
                "technology."),
            React.createElement("p", null, "We believe that with the right tools, users can master diverse subjects, uncover connections across disciplines, and gain a deeper understanding of topics at a fundamental level, thanks to the wealth of free information available online and in digital libraries."))));
};
export { AboutSection };
