import React, { useEffect, useRef } from "react";
import { useHomepageCtx } from "./HomepageCtx";
import obFavicon128 from "../../assets/img/ob-favicon128.png";
// Utils //
const isDevMode = () => {
    return process.env.NODE_ENV === "development";
};
// Views //
const Header = () => {
    const { setHeaderHeight } = useHomepageCtx();
    const headerRef = useRef(null);
    // Update the state of header height to be used in other components
    useEffect(() => {
        if (headerRef.current) {
            const height = headerRef.current.offsetHeight;
            setHeaderHeight(height);
        }
    }, [setHeaderHeight]);
    return (React.createElement("div", { ref: headerRef, style: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            position: "sticky",
            top: "0",
            zIndex: "1000",
            backgroundColor: "white",
            borderBottom: "2px solid #f0f0f0", // #f0f0f0, #eaeaea
        } },
        React.createElement(TopBar, null),
        React.createElement(DevModeIndicator, null),
        React.createElement("div", { style: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                margin: "12px 24px",
                padding: "0px 0px",
            } },
            React.createElement("div", { style: {
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    flexGrow: "2",
                } },
                React.createElement(Logo, null)),
            React.createElement("div", { style: { flexGrow: "8" } },
                React.createElement(TabLinks, null)))));
};
const TopBar = () => {
    return (React.createElement("div", { style: {
            height: "4px",
            backgroundColor: "#a4ff00", // #dddddd, #a4ff00
        } }));
};
const Logo = () => {
    const { themeAlt, setThemeAlt } = useHomepageCtx();
    return (React.createElement("div", { style: {
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            alignItems: "center",
        }, onClick: () => setThemeAlt(!themeAlt) },
        React.createElement("img", { style: { width: "32px", height: "32px" }, src: obFavicon128, alt: "Icon" }),
        React.createElement("p", { style: {
                fontSize: "20px",
                fontWeight: "bold",
                fontFamily: "Courier",
                color: "#2a2a2a",
            } }, "Octobase")));
};
const TabLinks = () => {
    return (React.createElement("div", { style: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
        } },
        React.createElement(TabBtn, { label: "About", tabId: "about" }),
        React.createElement(TabBtn, { label: "Products", tabId: "products" }),
        React.createElement(TabBtn, { label: "Contact", tabId: "contact" }),
        React.createElement(TabBtn, { label: "Careers", tabId: "careers" })));
};
const TabBtn = ({ label, tabId }) => {
    const { selectedTab, setSelectedTab, headerHeight } = useHomepageCtx();
    const handleBtnClick = (tab) => {
        setSelectedTab(tab);
        const sectionElement = document.getElementById(tab);
        if (sectionElement) {
            // Get the position of the section element
            const elementPosition = sectionElement.getBoundingClientRect().top;
            // Offset the position by the header height to account for the sticky header
            const offsetPosition = elementPosition + window.scrollY - headerHeight - 24;
            // Scroll to the section element
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
    };
    const isSelected = selectedTab === tabId;
    return (React.createElement("button", { style: {
            fontSize: "12px",
            fontFamily: "Courier",
            fontWeight: "bold",
            color: "black",
            backgroundColor: isSelected ? "#a4ff00" : "white",
            border: isSelected ? "1px solid black" : "1px solid #dddddd",
            borderRadius: "4px",
            padding: "4px 8px",
            margin: "4px 4px",
            cursor: "pointer",
        }, onMouseEnter: (e) => {
            e.stopPropagation();
            e.currentTarget.style.border = "1px solid black";
            e.currentTarget.style.backgroundColor = "#a4ff04";
        }, onMouseLeave: (e) => {
            e.stopPropagation();
            e.currentTarget.style.border = isSelected
                ? "1px solid black"
                : "1px solid #dddddd";
            e.currentTarget.style.backgroundColor = isSelected
                ? "#a4ff00"
                : "white";
        }, onClick: () => handleBtnClick(tabId) }, isSelected ? `*${label}` : label));
};
const DevModeIndicator = () => {
    return isDevMode() ? (React.createElement("span", { style: {
            position: "absolute",
            zIndex: "10",
            top: "4px",
            right: "22px",
            fontFamily: "Courier",
            fontSize: "10px",
            fontWeight: "bold",
            color: "crimson",
        } }, "[dev mode]")) : null;
};
export default Header;
